/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Navigate, useLocation } from 'react-router-dom';
import { sendGTMDataLayer } from '../utils';
import constants from '../constants';

const ProtectedRoute = props => {
  const { children, store } = props;
  const {
    UserInfo: { isAuthorized, uuid, roleId }
  } = store;
  const path = useLocation();

  useEffect(() => {
    /* eslint-disable */
    uuid && roleId && sendGTMDataLayer();
  }, []);

  if(window.location.hostname.includes(constants.PAFURL)){
    return <Navigate to="/deprecated" state={{ prevUrl: path }}  />
  }

  return isAuthorized ? <>{children}</> : <Navigate to="/forbidden" />;
};

ProtectedRoute.propTypes = {
  store: PropTypes.shape({
    UserInfo: PropTypes.shape({
      isAuthorized: PropTypes.bool.isRequired,
      firstName: PropTypes.string.isRequired,
      uuid: PropTypes.string.isRequired,
      roleId: PropTypes.string.isRequired
    })
  }),
  children: PropTypes.node.isRequired
};

export default inject('store')(observer(ProtectedRoute));
